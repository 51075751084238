import depositSettingRoutes from "./DepositSetting/depositSettingRoutes";
import paymentMethodRoutes from "./PaymentMethod/paymentMethodRoutes";
import productSettingRoutes from "./ProductSetting/productSettingRoutes";
import companySettingRoutes from "./CompanySetting/companySettingRoutes";
import membershipSettingRoutes from "./MembershipSetting/membershipSettingRoutes";
import roleAdministrationRoutes from "./Roles/roleAdministrationRoutes";
import loanAppraisalRoutes from "./LoanAppraisalSettings/loanAppraisalRoutes";
import loanTemplatesRoutes from "./LoanTemplates/loanTemplatesRoutes"
import failedJobRoutes from "./FailedJobs/FailedJobsRoutes"

import loandocumentation from "./LoanDocumentationFee/loanDocumentationFeeRoutes";
import messageTemplateAdministrationModuleRoutes from "./MessageTemplate/messageTemplateAdministrationModuleRoutes";
import messageTemplateMasterAdministrationModuleRoutes from "./MessageTemplateMaster/messageTemplateMasterAdministrationModuleRoutes";
import configurationRoutes from "./Configuration/configurationRoutes";
import dataUploadRoutes from "./DataUpload/dataUploadRoutes";
import companyColorRoutes from "./CompanyColor/companyColorRoutes";
import makerCheckerAdministrationModuleRoutes from "./MakerChecker/makerCheckerAdministrationModuleRoutes";
import registrationFeeSettingRoutes from "./RegistrationFeeSetting/registrationFeeSettingRoutes";

import Base from '../../Common/Base.vue';
import pendingAdministrationRoutes from "./PendingAdministration/pendingAdministrationRoutes";

const administrationModuleRoutes = [

    {

        path: '',
        name: 'administrationHome',
        component: () => import('./HomeAdministration.vue'),
        meta: {
            auth: true,
            title: 'Administration',
            breadcrumb: 'Administration',
            permission: []
        },

    },

    {

        path: 'deposit-settings',
        name: 'depositSettingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Deposit Settings',
            breadcrumb: 'Deposit Settings',
            permission: ['view depositsetting', 'create depositsetting', 'update depositsetting', 'delete depositsetting']
        },

        children: depositSettingRoutes

    },

    {

        path: 'roles',
        name: 'roleHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Roles',
            breadcrumb: 'Roles',
            permission: ['view role', 'create role', 'update role', 'delete role']
        },

        children: roleAdministrationRoutes

    },

    {

        path: 'configuration',
        name: 'configurationHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Configuration',
            breadcrumb: 'Configuration',
            permission: ['view configuration', 'create configuration', 'update configuration', 'delete configuration']
        },

        children: configurationRoutes

    },

    {

        path: 'product-settings',
        name: 'productSettingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Loan Product Settings',
            breadcrumb: 'Loan Product Settings',
            permission: ['view productsetting', 'create productsetting', 'update productsetting', 'delete productsetting']
        },

        children: productSettingRoutes

    },

    {

        path: 'company-settings',
        name: 'companySettingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Company Settings',
            breadcrumb: 'Company Settings',
            permission: ['view companysetting', 'update companysetting', 'approve companysetting']
        },

        children: companySettingRoutes

    },
    {
        path: 'membership-settings',
        name: 'membershipSettingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Membership Settings',
            breadcrumb: 'Membership Settings',
            permission: ['view companysetting', 'create companysetting', 'update companysetting', 'approve companysetting']
        },
        children: membershipSettingRoutes
    },

    {

        path: 'financingfee-settings',
        name: 'financingfeeHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Financing Fee',
            breadcrumb: 'Financing Fee',
            permission: []
        },

        children: loandocumentation

    },

    {

        path: 'loanappraisal-settings',
        name: 'loanAppraisalSettingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Loan Appraisal',
            breadcrumb: 'Loan Appraisal',
            permission: []
        },

        children: loanAppraisalRoutes

    },
    {
        path: 'loan-offer-letter-templates',
        name: 'loanOfferLetterTemplates',
        component: Base,
        meta: {
            auth: true,
            title: 'Loan Offer Letter Templates',
            breadcrumb: 'Loan Offer Letter Templates',
            permission: []
        },

        children: loanTemplatesRoutes

    },
    {
        path: 'failed-jobs',
        name: 'failedJobs',
        component: Base,
        meta: {
            auth: true,
            title: 'Failed Jobs',
            breadcrumb: 'Failed Jobs',
            permission: []
        },

        children: failedJobRoutes

    },

    {

        path: 'payment-methods',
        name: 'paymentMethodHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Payment Methods',
            breadcrumb: 'Payment Methods',
            permission: ['view paymentmethod', 'create paymentmethod', 'update paymentmethod', 'delete paymentmethod']
        },

        children: paymentMethodRoutes

    },

    {

        path: 'data-uploads',
        name: 'dataUploadHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Data uploads',
            breadcrumb: 'Data Uploads',
            permission: ['view dataupload', 'create dataupload', 'update dataupload', 'delete dataupload']
        },

        children: dataUploadRoutes

    },

    {

        path: 'message-templates',
        name: 'messageTemplateHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Message Templates',
            breadcrumb: 'Message Templates',
            permission: ['view messagetemplate', 'create messagetemplate', 'update messagetemplate', 'delete messagetemplate']
        },

        children: messageTemplateAdministrationModuleRoutes

    },

    {

        path: 'message-templates-master',
        name: 'messageTemplateMasterHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Message Templates Master',
            breadcrumb: 'Message Templates Master',
            permission: ['view messagetemplatemaster', 'create messagetemplatemaster', 'update messagetemplatemaster', 'delete messagetemplatemaster']
        },

        children: messageTemplateMasterAdministrationModuleRoutes

    },

    {

        path: 'maker-checker',
        name: 'makerCheckerHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Maker Checker',
            breadcrumb: 'Maker Checker',
            permission: [
                         'view makercheckersectionmaster', 'create makercheckersectionmaster',
                         'update makercheckersectionmaster', 'delete makercheckersectionmaster',
                         'view makercheckersection', 'create makercheckersection',
                         'update makercheckersection', 'delete makercheckersection'
                        ]
        },

        children: makerCheckerAdministrationModuleRoutes

    },

    {

        path: 'company-colors',
        name: 'companyColorsHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Company Colors',
            breadcrumb: 'Company Colors',
            permission: ['view companycolor', 'create companycolor', 'update companycolor', 'delete companycolor']
        },

        children: companyColorRoutes

    },

    {

        path: 'pending-items',
        name: 'pendingAdministrationHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Pending Items',
            breadcrumb: 'Pending Items',
            permission: ['view makerchecker', 'update makerchecker']
        },

        children: pendingAdministrationRoutes

    },

    {

        path: 'registrationfee-settings',
        name: 'registrationFeeSettings',
        component: Base,
        meta: {
            auth: true,
            title: 'Registration Fee Settings',
            breadcrumb: 'Registration Fee Settings',
            permission: []
        },

        children: registrationFeeSettingRoutes

    },

];

export default administrationModuleRoutes;
