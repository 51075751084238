const loanAppraisalRoutes = [

    {

        name: 'financingfeeHome',
        path: '',
        component: () => import('./ListLoanDocumentationFee.vue'),
        meta: {
            auth: true,
            title: 'Financing Fee Listing',
            breadcrumb: 'Financing Fee Listing',
            permission: []
        }
        

    },
    {
        path: 'create',
        name: 'createfinancingfee',
        component: () =>
            import ('./CreateEditDocFee.vue'),
        meta: {
            auth: true,
            title: 'Create Financing Fee',
            breadcrumb: 'Create Financing Fee',
            permission: []
        }
    },

    {
        path: ':id/edit',
        name: 'editfinancingfee',
        component: () =>
            import ('./CreateEditDocFee.vue'),
        meta: {
            auth: true,
            title: 'Edit Financing Fee',
            breadcrumb: 'Edit Financing Fee',
            permission: []
        }
    },


];

export default loanAppraisalRoutes;
