const loanAppraisalRoutes = [

    {

        name: 'loanAppraisalHome',
        path: '',
        component: () => import('./ListLoanAppraisal.vue'),
        meta: {
            auth: true,
            title: 'Loan Appraisal Listing',
            breadcrumb: 'Loan Appraisal Listing',
            permission: []
        }
        

    },
    {
        path: ':id/details',
        name: 'loanappraisaldetail',
        component: () => import('./LoanAppraisalDetail.vue'),
        meta: {
            auth: true,
            title: 'Loan Appraisal Details',
            breadcrumb: 'Loan Appraisal Details',
            permission: []
        }
    },

];

export default loanAppraisalRoutes;
