const businessAppraisalRoutes = [

    {
        path: 'businessappraisal',
        name: 'businessappraisal',
        component: () => import ('./BusinessAppraisal.vue'),
        meta: {
            auth: true,
            title: 'Business Appraisal Form',
            breadcrumb: 'Business Appraisal Form',
            permission: [] //'update collateral'
        }
    },

    {
        path: 'listbusinessappraisal',
        name: 'listbusinessappraisal',
        component: () => import ('./ListBusinessAppraisal.vue'),
        meta: {
            auth: true,
            title: 'Business Appraisal',
            breadcrumb: 'Business Appraisal',
            permission: [] //'update collateral'
        }
    },

    {
        path: 'createbusinessappraisal',
        name: 'createbusinessappraisal',
        component: () => import ('./BusinessAppraisal.vue'),
        meta: {
            auth: true,
            title: 'Create Business Appraisal',
            breadcrumb: 'Create Business Appraisal',
            permission: [] //'update collateral'
        }
    },


    {
        path: ':id/editappraisal',
        name: 'editbusinessappraisal',
        component: () => import ('./BusinessAppraisal.vue'),
        meta: {
            auth: true,
            title: 'Edit Business Appraisal',
            breadcrumb: 'Edit Business Appraisal',
            permission: []
        }
        
    },

];


export default businessAppraisalRoutes;
