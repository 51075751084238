const loanRoutes = [

    {

        name: 'loanHome',
        path: '',
        component: () =>
            import('./ListLoans.vue'),
        meta: {
            auth: true,
            title: 'Finance Listing',
            breadcrumb: 'Finance Listing',
            permission: ['view loan']
        }

    },

    {
        path: 'customer-loans/:customerId',
        name: 'customerLoans',
        component: () =>
            import('./ListCustomerLoans.vue'),
        meta: {
            auth: true,
            title: 'Customer Loans',
            breadcrumb: 'Customer Loans',
            permission: ['view loan']
        },
        children: [

            {

                name: 'showCustomerLoan',
                path: ':loanId',
                component: () =>
                    import('./ListCustomerLoans.vue'),
                meta: {
                    auth: true,
                    title: 'Showing Customer Loan',
                    breadcrumb: 'Showing Customer Loan',
                    permission: ['view loan']
                }


            },

        ]
    },





    {
        path: 'show/:id',
        name: 'showLoan',
        component: () =>
            import('./ShowLoan.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Application',
            breadcrumb: 'Showing Loan Application',
            permission: ['view loan'],
            section: 'loanapplication'
        }

    },

    {
        path: 'viewaccount/:id',
        name: 'viewCustomerAccount',
        component: () =>
            import('./ViewAccount.vue'),
        meta: {
            auth: true,
            title: 'View Account',
            breadcrumb: 'View Account',
            permission: []
        }

    },

    {
        path: 'defaultedinstallment',
        name: 'defaultedinstallmentreport',
        component: () =>
            import('./report/LoanDefaultedInstallmentReport.vue'),
        meta: {
            auth: true,
            title: 'Installment Due Report',
            breadcrumb: 'Installment Due Report',
            permission: []
        }

    },

    {
        path: 'defaulters',
        name: 'defaultersreport',
        component: () =>
            import('./report/LoanDefaultersReport.vue'),
        meta: {
            auth: true,
            title: 'Defaulters Report',
            breadcrumb: 'Defaultes Report',
            permission: []
        }

    },

    {
        path: 'disbursementreport',
        name: 'facilitydisbursementreport',
        component: () =>
            import('./report/LoanPaymentReport.vue'),
        meta: {
            auth: true,
            title: 'Disbursement Report',
            breadcrumb: 'Disbursement Report',
            permission: []
        }

    },

];

export default loanRoutes;
