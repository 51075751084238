const PaymentManagementRoutes = [
    {
        path: 'mpesa-payment',
        name: 'MpesaPayment',
        component: () => import('./Mpesa.vue'),
        meta: {
            auth: true,
            title: 'Mpesa Payment',
            breadcrumb: 'Mpesa Payment',
            permission: []
        },
    },

    {
        path: 'mpesa-reconciliation',
        name: 'MpesaReconciliation',
        component: () => import('./Reconciliation.vue'),
        meta: {
            auth: true,
            title: 'Mpesa Reconciliation',
            breadcrumb: 'Mpesa Reconciliation',
            permission: []
        },
    },

];

export default PaymentManagementRoutes;