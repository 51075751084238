const registrationFeeSettingRoutes = [

    {

        name: 'registrationFeeSettingHome',
        path: '',
        component: () => import('./ListRegistrationFeeSettings.vue'),
        meta: {
            auth: true,
            title: 'Registration Fee Settings',
            breadcrumb: 'Registration Fee Settings',
            permission: []
        }

    },

    {
        path: 'create',
        name: 'createRegistrationFeeSetting',
        component: () => import('./CreateEditRegistrationSettingDetails.vue'),
        meta: {
            auth: true,
            title: 'Registration Fee Setting',
            breadcrumb: 'Create Registration Fee Setting',
            permission: []
        }
    },

    {
        path: ':id/edit',
        name: 'editRegistrationFeeSetting',
        component: () => import('./CreateEditRegistrationSettingDetails.vue'),
        meta: {
            auth: true,
            title: 'Edit Registration Fee Setting',
            breadcrumb: 'Edit Registration Fee Setting',
            permission: []
        }
    }

];

export default registrationFeeSettingRoutes;
