const companyColorRoutes = [

    {

        name: 'companyColorHome',
        path: '',
        component: () => import('./ListCompanyColors.vue'),
        meta: {
            auth: true,
            title: 'Company Colors Listing',
            breadcrumb: 'Company Colors Listing',
            permission: ['view companycolor']
        }

    },

    {
        path: 'create',
        name: 'createCompanyColor',
        component: () => import('./CreateCompanyColor.vue'),
        meta: {
            auth: true,
            title: 'Create Company Color',
            breadcrumb: 'Create Company Color',
            permission: ['create companycolor']
        }
    },

    {
        path: ':id',
        name: 'showCompanyColor',
        component: () => import('./ShowCompanyColor.vue'),
        meta: {
            auth: true,
            title: 'Showing Company Color',
            breadcrumb: 'Showing Company Color',
            permission: ['view companycolor']
        }
    },

    {
        path: ':id/edit',
        name: 'editCompanyColor',
        component: () => import('./EditCompanyColor.vue'),
        meta: {
            auth: true,
            title: 'Edit Company Color',
            breadcrumb: 'Edit Company Color',
            permission: ['update companycolor']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteCompanyColor',
        meta: {
            auth: true,
            title: 'Delete Company Color',
            permission: ['delete companycolor']
        }
    },

];

export default companyColorRoutes;
