<template>
  <div>
    <div class="container-fluid no-gutters px-0">
      <div class="row">
        <div class="col-lg-12 mt-3xx mb-2">
          <search-bar :bread-crumb-only="true"></search-bar>

          <!-- tabs -->
          <div class="card">
            <div class="card-body">
              <!-- <div class="register-icon">
                      <img
                        class="register-icon-item"
                        src="../../../../assets/images/logo-sm.png"
                        alt="snb logo"
                      />
                    </div>  -->

              <div class="register-stepper">
                <div class="step" :class="{
                  'step-active': step === 1,
                  'step-done': step > 1,
                }">
                  <span class="step-number">1</span>
                </div>
                <div class="step" :class="{
                  'step-active': step === 2,
                  'step-done': step > 2,
                }">
                  <span class="step-number">2</span>
                </div>
                <div class="step" :class="{
                  'step-active': step === 3,
                  'step-done': step > 3,
                }" v-if="isEdit">
                  <span class="step-number" v-if="isEdit">3</span>
                </div>
                <div class="step" :class="{
                  'step-active': step === 4,
                  'step-done': step > 4,
                }" v-if="isEdit">
                  <span class="step-number" v-if="isEdit">4</span>
                </div>
              </div>

              <transition class="mt-0" name="slide-fade">
                <section class="mt-0" v-show="step === 1">
                  <form class="form" method="post" action="#" @submit.prevent="next">
                    <div class="row">
                      <!--begin row for step 1-->
                      <!-- gender -->
                      <!-- <div class="col-lg-12">
                        <div class="form-group row">
                          <label for="gender" class="col-sm-4 col-form-label text-right">
                          </label>
                          <div class="col-sm-8">
                            <div class="form-check-inline my-1">
                              <div class="custom-control custom-radio">
                                <input type="radio" id="male" name="gender" :disabled="isShow"
                                  v-model="model.gender.$model" value="Male" class="custom-control-input" />
                                <label class="custom-control-label" for="male">Mr</label>
                              </div>
                            </div>
                            <div class="form-check-inline my-1">
                              <div class="custom-control custom-radio">
                                <input type="radio" id="female" name="gender" :disabled="isShow"
                                  v-model="model.gender.$model" value="Female" class="custom-control-input" />
                                <label class="custom-control-label" for="female">Mrs</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <!-- first name  -->
                      <!-- <div class="col-lg-4 mb-2">
                        <div class="form-outline" :class="{
                          'has-error': model.first_name.$invalid,
                        }">
                          <label class="form-label" for="first_name">
                            <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                              First Name</span>
                          </label>
                          <input class="form-control mt-n3" :class="{
                            'form-control-danger': model.first_name.$invalid,
                          }" type="text" placeholder="First Name" :disabled="isShow"
                            v-model="model.first_name.$model" />

                          <error-msg :property="model.first_name" />
                        </div>
                      </div> -->
                      <!-- last name -->
                      <!-- <div class="col-lg-4 mb-2">
                        <div class="form-outline" :class="{
                          'has-error': model.last_name.$invalid,
                        }">
                          <label class="form-label" for="last_name">
                            <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                              Last Name</span>
                          </label>
                          <input class="form-control mt-n3" :class="{
                            'form-control-danger': model.last_name.$invalid,
                          }" type="text" placeholder="Last Name" :disabled="isShow"
                            v-model="model.last_name.$model" />

                          <error-msg :property="model.last_name" />
                        </div>
                      </div> -->

                      <!-- email -->
                      <!-- <div class="col-lg-4 mb-2">
                        <div class="form-outlinex" :class="{ 'has-error': model.email.$invalid }">
                          <label class="form-label" for="email">
                            <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                              Email
                            </span>
                          </label>
                          <input class="form-control mt-n3" :class="{
                            'form-control-danger': model.email.$invalid,
                          }" type="text" placeholder="Email" :disabled="isShow" v-model="model.email.$model" />
                          <error-msg :property="model.email" />
                        </div>
                      </div> -->

                      <!-- ID Number -->
                      <!-- <div class="col-lg-4 mb-2">
                        <div class="form-outline" :class="{ 'has-error': model.id_no.$invalid }">
                          <label class="form-label" for="id_no">
                            <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                              ID Number
                            </span>
                          </label>
                          <input class="form-control mt-n3" :class="{
                            'form-control-danger': model.id_no.$invalid,
                          }" type="text" placeholder="ID Number" :disabled="isShow" v-model="model.id_no.$model" />
                          <error-msg :property="model.id_no" />
                        </div>
                      </div> -->

                      <!-- first name  -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.first_name.$invalid }">
                          <label for="first_name"> First Name </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.first_name.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.first_name.$model" />
                          <error-msg :property="model.first_name" />
                        </div>
                      </div>

                      <!-- last name -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.last_name.$invalid }">
                          <label for="last_name"> Last Name </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.last_name.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.last_name.$model" />
                          <error-msg :property="model.last_name" />
                        </div>
                      </div>

                      <!-- Date of Birth -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.date_of_birth.$invalid }">
                          <label for="date_of_birth"> Date of Birth </label>
                          <!-- <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.date_of_birth.$invalid,
                          }"
                          type="date"
                          :disabled="isShow" placeholder="Birthday ('day'/'month'/'year')"
                          v-model="model.date_of_birth.$model" required
                        /> -->

                          <!-- @onchange="onDateOfBirthChange" -->

                          <my-date-picker :mode="'date'" :title="'Date of Birth'" :styles="'text-left'"
                            v-model="model.date_of_birth.$model" :isDisabled="isShow">
                          </my-date-picker>

                          <error-msg :property="model.date_of_birth" />
                        </div>
                      </div>

                      <!-- email -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.email.$invalid }">
                          <label for="email"> Email </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.email.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.email.$model" />
                          <error-msg :property="model.email" />
                        </div>
                      </div>

                      <!-- ID Number -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.id_no.$invalid }">
                          <label for="id_no"> ID Number </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.id_no.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.id_no.$model" />
                          <error-msg :property="model.id_no" />
                        </div>
                      </div>

                      <!--- Marital Status -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.marital_status_id.$invalid }">
                          <label for="marital_status_id"> Marital Status </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.marital_status_id.$model"
                              v-if="maritalstatuses.data">
                              <option value="" selected v-if="!isEdit">
                                Select Marital Status
                              </option>
                              <option v-for="(item, index) in maritalstatuses.data" :key="index"
                                :value="item.marital_status_id">
                                {{ item.name }}
                              </option>
                            </select>
                            <error-msg :property="model.marital_status_id" />
                          </div>
                        </div>
                      </div>

                      <!--- Phone  -->
                      <div class="col-lg-2 col-md-2">
                        <div class="form-outline" :class="{
                          'has-error': model.phone_country.$invalid,
                        }">
                          <label for="phone_country"> Phone </label>
                          <select class="form-control" :disabled="isShow" v-model="model.phone_country.$model"
                            v-if="countries.data">
                            <option value="" selected v-if="!isEdit">Select Code</option>
                            <option v-for="(item, index) in countries.data" :key="index" :value="item.iso2">
                              {{ item.iso2 }} ({{ item.phonecode }})
                            </option>
                          </select>

                          <error-msg :property="model.phone_country" />
                        </div>
                      </div>

                      <div class="col-lg-2 col-md-2">
                        <div class="form-outline" :class="{
                          'has-error': model.phone.$invalid,
                        }">
                          <label for="phone"> &nbsp; </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.phone.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.phone.$model" />

                          <error-msg :property="model.phone" />
                        </div>
                      </div>

                      <!--- Alternative Phone -->
                      <div class="col-lg-2 col-md-2">
                        <div class="form-outline" :class="{
                          'has-error': model.alternative_phone_country.$invalid,
                        }">
                          <label for="alternative_phone_country"> Alternative Phone </label>
                          <select class="form-control" :disabled="isShow"
                            v-model="model.alternative_phone_country.$model" v-if="countries.data">
                            <option value="" selected v-if="!isEdit">Select Code</option>
                            <option v-for="(item, index) in countries.data" :key="index" :value="item.iso2">
                              {{ item.iso2 }} ({{ item.phonecode }})
                            </option>
                          </select>

                          <error-msg :property="model.alternative_phone_country" />
                        </div>
                      </div>

                      <div class="col-lg-2 col-md-2">
                        <div class="form-outline" :class="{
                          'has-error': model.alternative_phone.$invalid,
                        }">
                          <label for="alternative_phone"> &nbsp; </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.alternative_phone.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.alternative_phone.$model" />

                          <error-msg :property="model.alternative_phone" />
                        </div>
                      </div>

                      <!-- Gender -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.date_of_birth.$invalid }">
                          <label for="gender"> Gender </label>
                          <div class="col-sm-8x">
                            <div class="form-check-inline my-1">
                              <div class="custom-control custom-radio">
                                <input type="radio" id="male" name="gender" :disabled="isShow"
                                  v-model="model.gender.$model" value="Male" class="custom-control-input" />
                                <label class="custom-control-label" for="male">Male</label>
                              </div>
                            </div>
                            <div class="form-check-inline my-1">
                              <div class="custom-control custom-radio">
                                <input type="radio" id="female" name="gender" :disabled="isShow"
                                  v-model="model.gender.$model" value="Female" class="custom-control-input" />
                                <label class="custom-control-label" for="female">Female</label>
                              </div>
                            </div>
                          </div>
                          <error-msg :property="model.gender" />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <hr class="row no-top-margin" />
                      </div>

                      <div class="col-lg-12">
                        <!-- {{ model.latitude.$model }}  ----
                                            {{ model.longitude.$model }} -->

                        <div class="form-group">
                          <label for="street_address" class="col-sm-12 col-form-label no-gutters px-0 text-left">Member
                            Location
                          </label>

                          <div class="col-sm-12 no-gutters px-0">
                            <map-leaflet :latlng="{
                              lat:
                                model.latitude.$model !== null &&
                                  model.latitude.$model !== undefined
                                  ? model.latitude.$model
                                  : -1.284569235418726,
                              lng:
                                model.longitude.$model !== null &&
                                  model.longitude.$model !== undefined
                                  ? model.longitude.$model
                                  : 36.888678073883064,
                            }" :streetAddress="model.street_address.$model" @changedLocation="changedLocation"
                              @update:latlng="updateLatLng" :isEdit="isEdit" :isShow="isShow" :isNew="isNew" />
                            <!-- v-if="model.latitude.$model && model.longitude.$model" -->
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12" v-if="!isShow && !isEdit">
                        <hr class="row no-top-margin" />
                      </div>

                      <!-- Generate password -->
                      <!-- <div class="col-lg-6 transition" v-if="!isShow && !isEdit"> -->
                      <!-- Password -->
                      <div class="col-lg-6 col-md-6 transition" v-if="!isShow && !isEdit">
                        <div class="form-group" :class="{ 'has-error': model.password.$invalid }"
                          v-if="model.auto_generate_password.$model == false">
                          <label for="password"> Password </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.password.$invalid,
                          }" type="password" :disabled="isShow" v-model="model.password.$model" required />
                          <error-msg :property="model.password" />
                        </div>
                      </div>

                      <!-- Password Confirm -->
                      <div class="col-lg-6 col-md-6 transition" v-if="!isShow && !isEdit">
                        <div class="form-group" :class="{ 'has-error': model.password_confirmation.$invalid }"
                          v-if="model.auto_generate_password.$model == false">
                          <label for="password_confirmation"> Password Confirm </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.password_confirmation.$invalid,
                          }" type="password" :disabled="isShow" v-model="model.password_confirmation.$model"
                            required />
                          <error-msg :property="model.password_confirmation" />
                        </div>
                      </div>

                      <!-- Automatically Generate User Password -->
                      <div class="col-lg-6 col-md-6 transition" v-if="!isShow && !isEdit">
                        <div class="form-group row">
                          <!-- <label for="auto_generate_password" class="col-sm-4 col-form-label text-right">&nbsp;</label> -->
                          <div class="col-sm-12">
                            <div class="custom-control custom-switch mt-2">
                              <input type="checkbox" class="custom-control-input" :disabled="isShow"
                                id="auto_generate_password" v-model="model.auto_generate_password.$model"
                                :checked="model.auto_generate_password" @change="checkAutoGeneratePassword($event)" />
                              <label class="custom-control-label" for="auto_generate_password">
                                Automatically Generate User Password
                              </label>
                            </div>
                            <error-msg :property="model.auto_generate_password" />
                          </div>
                        </div>
                      </div>

                      <!-- User Must Change Password At Next Login -->
                      <div class="col-lg-6 col-md-6 transition" v-if="!isShow && !isEdit">
                        <div class="form-group row">
                          <!-- <label for="change_password_next_login" class="col-sm-4 col-form-label text-right">&nbsp;</label> -->
                          <div class="col-sm-12">
                            <div class="custom-control custom-switch mt-2">
                              <input type="checkbox" class="custom-control-input" :disabled="isShow"
                                id="change_password_next_login" v-model="model.change_password_next_login.$model"
                                :checked="model.change_password_next_login"
                                @change="checkMustChangePasswordNextLogin($event)" />
                              <label class="custom-control-label" for="change_password_next_login">
                                User Must Change Password At Next Login
                              </label>
                            </div>
                            <error-msg :property="model.change_password_next_login" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end row row for step 1-->
                    <hr class="row" />
                    <div class="btn-group">
                      <button class="btn btn-primary">
                        Next &nbsp;
                        <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </section>
              </transition>

              <!-- step 2 -->
              <transition name="slide-fade">
                <section v-show="step === 2">
                  <form class="form" method="post" action="#" @submit.prevent="next">
                    <!-- start step 2 inputs -->
                    <div class="row">
                      <!-- Nationality -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.nationality_id.$invalid }">
                          <label for="nationality_id"> Nationality </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.nationality_id.$model"
                              v-if="countries.data">
                              <option value="" selected v-if="!isEdit">
                                Select Nationality
                              </option>
                              <option v-for="(item, index) in countries.data" :key="index" :value="item.country_id">
                                {{ item.name }}
                              </option>
                            </select>
                            <error-msg :property="model.nationality_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Country -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.country_id.$invalid }">
                          <label for="country_id"> Country </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.country_id.$model"
                              v-if="countries.data" @change="onChangeCountry($event)">
                              <option value="" selected v-if="!isEdit">
                                Select Country
                              </option>
                              <option v-for="(item, index) in countries.data" :key="index" :value="item.country_id">
                                {{ item.name }}
                              </option>
                            </select>
                            <error-msg :property="model.country_id" />
                          </div>
                        </div>
                      </div>

                      <!-- County -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.county_id.$invalid }">
                          <label for="county_id"> County </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.county_id.$model"
                              v-if="counties.data" @change="onChangeCounty($event)">
                              <option value="" selected v-if="!isEdit">
                                Select County
                              </option>
                              <option v-for="(item, index) in counties.data" :key="index" :value="item.county_id">
                                {{ item.name }}
                              </option>
                            </select>
                            <error-msg :property="model.county_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Constituency -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.constituency_id.$invalid }">
                          <label for="constituency_id"> Constituency </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.constituency_id.$model"
                              v-if="constituencies.data" @change="onChangeConstituency($event)">
                              <option value="" selected v-if="!isEdit">
                                Select Constituency
                              </option>
                              <option v-for="(item, index) in constituencies.data" :key="index"
                                :value="item.constituency_id">
                                {{ item.name }}
                              </option>
                            </select>

                            <records-not-found :message="'Please select <strong>county</strong>'" v-else />
                            <error-msg :property="model.constituency_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Ward -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.ward_id.$invalid }">
                          <label for="ward_id"> Ward </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.ward_id.$model"
                              v-if="wards.data">
                              <option value="" selected v-if="!isEdit">
                                Select Ward
                              </option>
                              <option v-for="(item, index) in wards.data" :key="index" :value="item.ward_id">
                                {{ item.name }}
                              </option>
                            </select>

                            <records-not-found :message="'Please select <strong>constituency</strong>'" v-else />
                            <error-msg :property="model.ward_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Street Address -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.street_address.$invalid }">
                          <label for="street_address"> Street Address </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.street_address.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.street_address.$model" />
                          <error-msg :property="model.street_address" />
                        </div>
                      </div>

                      <!-- Postal Address -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.postal_address.$invalid }">
                          <label for="postal_address"> Postal Address </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.postal_address.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.postal_address.$model" />
                          <error-msg :property="model.postal_address" />
                        </div>
                      </div>

                      <!-- Postal Code -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.postal_code.$invalid }">
                          <label for="postal_code"> Postal Code </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.postal_code.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.postal_code.$model" />
                          <error-msg :property="model.postal_code" />
                        </div>
                      </div>

                      <!-- <div class="col-lg-12" v-if="!isShow && !isEdit"> -->
                      <!-- <div class="ribbon"> -->
                      <!-- <span class="text"> Banking and Savings</span> -->
                      <!-- </div> -->
                      <!-- <hr class="row" /> -->
                      <!-- </div>  -->

                      <!-- Company -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.company_id.$invalid }">
                          <label for="company_id"> Company </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.company_id.$model"
                              v-if="companies.data" @change="fetchBranches($event)">
                              <option value="" selected v-if="!isEdit">
                                Select Company
                              </option>
                              <option v-for="(item, index) in companies.data" :key="index" :value="item.company_id">
                                {{ item.company_name }}
                              </option>
                            </select>

                            <error-msg :property="model.company_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Branch -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.branch_id.$invalid }">
                          <label for="branch_id"> Branch </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.branch_id.$model"
                              v-if="branches.data">
                              <option value="" selected v-if="!isEdit">
                                Select Branch
                              </option>
                              <option v-for="(item, index) in branches.data" :key="index" :value="item.branch_id">
                                {{ item.branch_name }}
                              </option>
                            </select>

                            <error-msg :property="model.branch_id" />
                          </div>
                        </div>
                      </div>

                      <!-- Religion -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.religion.$invalid }">
                          <label for="ward_id"> Religion </label>
                          <div>
                            <select class="form-control" :disabled="isShow" v-model="model.religion.$model">
                              <option value="christian">Christian</option>
                              <option value="muslim">Muslim</option>
                              <option value="hindu">Hindu</option>
                              <option value="other">Other</option>
                            </select>

                            <error-msg :property="model.religion" />
                          </div>
                        </div>
                      </div>

                      <!-- Source of Income -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.source_of_income.$invalid }">
                          <label for="source_of_income"> Source of Income </label>
                          <input class="form-control" :class="{
                            'form-control-danger': model.source_of_income.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.source_of_income.$model" />
                          <error-msg :property="model.source_of_income" />
                        </div>
                      </div>

                      <!-- Monthly Target -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{ 'has-error': model.monthly_target_savings.$invalid }">
                          <label for="monthly_target_savings"> Monthly Target </label>
                          <input class="form-control" :class="{
                            'form-control-danger':
                              model.monthly_target_savings.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.monthly_target_savings.$model" />
                          <error-msg :property="model.monthly_target_savings" />
                        </div>
                      </div>

                      <!-- Monthly Target Savings Date -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group" :class="{
                          'has-error': model.monthly_target_savings_date.$invalid,
                        }">
                          <label for="monthly_target_savings_date">
                            Monthly Target Savings Date
                          </label>
                          <input class="form-control" :class="{
                            'form-control-danger':
                              model.monthly_target_savings_date.$invalid,
                          }" type="text" :disabled="isShow" v-model="model.monthly_target_savings_date.$model" />
                          <error-msg :property="model.monthly_target_savings_date" />
                        </div>
                      </div>


                      <div class="col-lg-4 col-md-4">
                        <label>Team</label>
                        <div class="form-group">
                          <select class="form-control" v-model="model.team_id.$model" v-if="teams.data">
                            <option :value="''">Team Name</option>
                            <option v-for="(item, index) in teams.data" :key="index" :value="item.team_id">
                              {{ item.team_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <!-- <div class="col-lg-6">

                                            <div class="form-group row">
                                                <label for="status_id" class="col-sm-4 col-form-label text-right">
                                                    Status
                                                </label>
                                                <div class="col-sm-8">

                                                    <select
                                                        class="form-control" :disabled="isShow"
                                                        v-model="model.status_id.$model"
                                                        v-if="statuses.data"
                                                        >
                                                        <option
                                                            v-for="(item, index) in statuses.data"
                                                            :key="index"
                                                            :value="item.status_id"
                                                        >
                                                            {{ item.status_name }}
                                                        </option>
                                                    </select>
                                                    <error-msg :property="model.status_id" />
                                                </div>
                                            </div>

                                        </div> -->
                    </div>

                    <hr class="row" />

                    <!-- end step 2 inputs -->
                    <!-- Begin : Previous and Next Buttons -->
                    <div class="btn-group">
                      <!-- <div class="text-center"> -->
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()">
                        <i class="fa fa-arrow-left"></i> &nbsp; Previous
                      </button>
                      <!-- </div> -->
                      <button class="btn btn-primary" v-if="!isEdit" @click.prevent="onSubmitted()">
                        Save
                        <i class="fa fa-save"></i>
                      </button>
                      <!-- <div class="cta"> -->
                      <button class="btn btn-primary" v-if="isEdit">
                        Next &nbsp;
                        <i class="fa fa-arrow-right"></i>
                      </button>
                      <!-- </div> -->
                    </div>
                    <!-- End: Previous and Next Buttons -->
                  </form>
                </section>
              </transition>
              <transition name="slide-fade">
                <section v-show="step === 3">
                  <form class="form" action="#" @submit.prevent="next">
                    <!-- begining of form inputs -->

                    <div class="tab-pane pt-3" id="other-details" role="tabpanel" v-if="isEdit">
                      <!-- {{ customer.customer_id }} -->

                      <!-- start next of kins -->
                      <div class="card">
                        <div class="card-header">
                          <p class="mb-0 text-muted">
                            Next of Kin &nbsp;
                            <button type="button" @click.prevent="createNextOfKinPopup()"
                              class="btn btn-primary btn-xs float-right">
                              Add Next of Kin<i class="fas fa-plus ml-1"></i>
                            </button>
                          </p>
                        </div>

                        <div class="card-body">
                          <div class="table-responsive-x loader-div">
                            <table class="table table-hoverx table-bordered mb-0"
                              v-if="nextofkins.data && nextofkins.data.length">
                              <thead>
                                <tr>
                                  <th width="20%">First Name</th>
                                  <th width="15%">Last Name</th>
                                  <th width="20%">Relationship</th>
                                  <th width="10%">Percentage</th>
                                  <th width="20%">Phone</th>
                                  <!-- <th width="15%">Status</th> -->
                                  <th width="15%">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(item, index) in nextofkins.data" :key="index">
                                  <td>{{ item.first_name }}</td>
                                  <td>{{ item.last_name }}</td>
                                  <td>{{ item.relationship }}</td>
                                  <td>{{ item.percentage }}</td>
                                  <td>{{ item.phone }}</td>
                                  <!-- <td>{{ item.status ? item.status.status_name : "" }}</td> -->
                                  <td class="text-left">
                                    <a @click.prevent="editNextOfKinPopup(item)" class="mr-2 custom-link"
                                      title="Edit record">
                                      <i class="las la-pen text-success font-18"></i>
                                    </a>

                                    <a @click.prevent="showNextOfKinPopup(item)" class="custom-link"
                                      title="Show record">
                                      <i class="las la-eye text-info font-18"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <records-not-found :message="'Member <strong>Next of Kin</strong> Records Not Found'"
                              v-else />
                          </div>
                        </div>
                      </div>
                      <!-- end next of kins -->

                      <hr />

                      <!-- start user banks -->
                      <div class="card">
                        <!-- {{ userbanks }} -->
                        <div class="card-header">
                          <p class="mb-0 text-muted">
                            Bank Details &nbsp;
                            <button type="button" class="btn btn-primary btn-xs float-right"
                              @click.prevent="createUserBankPopup(customer)">
                              Add Bank Details <i class="fas fa-plus ml-1"></i>
                            </button>
                          </p>

                          <!-- <hr> -->
                        </div>

                        <div class="card-body">
                          <div class="table-responsive-x loader-div">
                            <table class="table table-hoverx table-bordered mb-0"
                              v-if="userbanks.data && userbanks.data.length">
                              <thead>
                                <tr>
                                  <th width="15%">Bank Name</th>
                                  <th width="15%">Branch</th>
                                  <th width="20%">Account No</th>
                                  <th width="20%">Primary Account</th>
                                  <th width="15%">Status</th>
                                  <th width="15%">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(item, index) in userbanks.data" :key="index">
                                  <td>{{ item.bank?.bank_name }}</td>
                                  <td>{{ item.branch_name }}</td>
                                  <td>{{ item.account_no }}</td>
                                  <td>
                                    {{ item.is_primary_account == true ? "Yes" : "No" }}
                                  </td>
                                  <td>{{ item.status?.status_name }}</td>
                                  <td class="text-left">
                                    <a @click.prevent="editUserBankPopup(item)" class="mr-2 custom-link"
                                      title="Edit record">
                                      <i class="las la-pen text-success font-18"></i>
                                    </a>

                                    <a @click.prevent="showUserBankPopup(item)" class="custom-link" title="Show record">
                                      <i class="las la-eye text-info font-18"></i>
                                    </a>

                                    <!-- <router-link class="mr-2" :to="{
                                                                        name: 'editCustomer',
                                                                        params: { id: item.customer_id },
                                                                }">
                                                                    <i class="las la-pen text-success font-18"></i>
                                                                </router-link>

                                                                <router-link class="mr-2" :to="{
                                                                    name: 'showCustomer',
                                                                    params: { id: item.customer_id },
                                                                }">
                                                                    <i class="las la-eye text-info font-18"></i>
                                                                </router-link> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <records-not-found :message="'Member <strong>Bank Details</strong> Not Found'" v-else />
                          </div>
                        </div>
                      </div>
                      <!-- end user banks -->

                      <!-- <hr>

                                    <p class="text-muted mb-0">More than one verification document i.e. Passport etc</p> -->
                    </div>

                    <!-- Begin : Previous and Next Buttons -->
                    <div class="btn-group">
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()" v-if="isEdit">
                        <i class="fa fa-arrow-left"></i>
                        &nbsp; Previous
                      </button>
                      <button class="btn btn-primary" v-if="isEdit">
                        Next &nbsp;
                        <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                    <!-- End: Previous and Next Buttons -->
                  </form>
                </section>
              </transition>
              <transition name="slide-fade" v-if="isEdit">
                <section v-show="step === 4" v-if="isEdit">
                  <form class="form" action="#" @submit.prevent="onSubmitted">
                    <!-- begining of form inputs -->

                    <div class="tab-pane pt-3" id="member-documents" role="tabpanel" v-if="isEdit">
                      <p class="mb-0 text-muted">Member Photo (Image)</p>

                      <div class="card card-image-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_photo'" :uploadItem="'image'" :disabled="isShow"
                              @uploadSuccess="onMemberPhotoUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right image-box transition">
                            <img v-if="customer?.member_photo?.length > 0" :src="customer.member_photo[0].thumb_path"
                              :alt="customer.first_name" class="rounded float-right" height="150" width="150" />

                            <div
                              class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                              v-else>
                              <span class="text-danger">No Member Image</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <p class="mb-0 text-muted">Member Signature (Image)</p>

                      <div class="card card-image-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_signature'" :uploadItem="'image'" :disabled="isShow"
                              @uploadSuccess="onMemberSignatureUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right image-box transition">
                            <img v-if="customer?.member_signature?.length"
                              :src="customer.member_signature[0].thumb_path" :alt="customer.first_name"
                              class="rounded float-right" height="150" width="150" />

                            <div
                              class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                              v-else>
                              <span class="text-danger">No Signature</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <p class="mb-0 text-muted">Identification Document Front (Image)</p>

                      <div class="card card-image-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_id_front'" :uploadItem="'image'" :disabled="isShow"
                              @uploadSuccess="onMemberIdFrontUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right image-box transition">
                            <img v-if="customer?.member_id_front?.length" :src="customer.member_id_front[0].thumb_path"
                              :alt="customer.first_name" class="rounded float-right" height="150" width="150" />

                            <div
                              class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                              v-else>
                              <span class="text-danger">No Identification Doc (Front Image)</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <p class="mb-0 text-muted">Identification Document Back (Image)</p>

                      <div class="card card-image-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_id_back'" :uploadItem="'image'" :disabled="isShow"
                              @uploadSuccess="onMemberIdBackUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right image-box transition">
                            <img v-if="customer?.member_id_back?.length" :src="customer.member_id_back[0].thumb_path"
                              :alt="customer.first_name" class="rounded float-right" height="150" width="150" />

                            <div
                              class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                              v-else>
                              <span class="text-danger">No Identification Doc (Back Image)</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <p class="mb-0 text-muted">Member KRA PIN (PDF)</p>

                      <div class="card card-doc-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_kra_pin'" :uploadItem="'doc'" :accept="'application/pdf'"
                              :disabled="isShow" @uploadSuccess="onMemberKraPinUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right doc-box transition">
                            <ul class="list-group" v-if="customer?.member_kra_pin?.length"
                              :src="customer.member_kra_pin[0].upload_path" :alt="customer.first_name">
                              <li class="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                  <i class="la la-file-pdf la-3x icon-center text-danger mr-2"></i>
                                  Member KRA PIN Document
                                </div>
                                <span>
                                  <button type="button" class="btn btn-outline-success waves-effect waves-light">
                                    <i class="la la-download font-18 mr-2"></i>Download
                                  </button>
                                </span>
                              </li>
                            </ul>

                            <ul class="list-group" v-else>
                              <li
                                class="list-group-item d-flex justify-content-between align-items-center doc-text-box">
                                <div class="text-right" style="align-self: center">
                                  <h4 class="text-danger la-sm-text">
                                    <i class="la la-file-pdf la-sm-text text-danger font-16 mr-2"></i>
                                    No KRA PIN Document
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <p class="mb-0 text-muted">Member Registration Form (PDF)</p>

                      <div class="card card-doc-box">
                        <div class="card-body">
                          <div class="col-sm-6 float-left transition" style="padding-left: 0">
                            <upload-file :section="'customer'" :sectionId="customer.customer_id"
                              :uploadType="'member_reg_form'" :uploadItem="'doc'" :accept="'application/pdf'"
                              :disabled="isShow" @uploadSuccess="onMemberRegFormUploadSuccess">
                            </upload-file>
                          </div>

                          <div class="col-sm-6 float-right doc-box transition">
                            <ul class="list-group" v-if="customer?.member_reg_form?.length > 0"
                              :src="customer.member_reg_form[0].upload_path" :alt="customer.first_name">
                              <li class="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                  <i class="la la-file-pdf la-3x icon-center text-danger mr-2"></i>
                                  Member Registration Form
                                </div>
                                <span>
                                  <button type="button" class="btn btn-outline-success waves-effect waves-light">
                                    <i class="la la-download font-18 mr-2"></i>Download
                                  </button>
                                </span>
                              </li>
                            </ul>

                            <ul class="list-group" v-else>
                              <li
                                class="list-group-item d-flex justify-content-between align-items-center doc-text-box">
                                <div class="text-right" style="align-self: center">
                                  <h4 class="text-danger la-sm-text">
                                    <i class="la la-file-pdf la-sm-text text-danger font-16 mr-2"></i>
                                    No Member Registration Form
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </div>

                    <!-- Begin : Previous and Next Buttons -->

                    <div class="btn-group" v-if="isMakerChecker">
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()" v-if="isEdit">
                        <i class="fa fa-arrow-left"></i>
                        &nbsp; Previous
                      </button>
                      <!-- show maker checker form buttons/ status -->
                      <maker-checker-button-component :makerChecker="makerChecker" />
                    </div>

                    <div class="btn-group" v-else>
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()" v-if="isEdit">
                        <i class="fa fa-arrow-left"></i>
                        &nbsp; Previous
                      </button>
                      <button class="btn btn-primary" @click.prevent="onSubmitted()" v-if="isEdit">
                        Finish &nbsp;
                        <i class="fa fa-save"></i>
                      </button>
                    </div>

                    <!-- <div class="btn-group center" v-if="isMakerChecker">
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()" v-if="isEdit">
                        <i class="fa fa-arrow-left">Previous</i>
                      </button>
                      <maker-checker-button-component
                        :makerChecker="makerChecker"
                      />
                    </div>

                    <div class="btn-group center" v-else>
                      <button class="btn btn-primary btn-danger mr-2" @click.prevent="previous()" v-if="isEdit">
                        <i class="fa fa-arrow-left">Previous</i>
                      </button>
                      <button class="btn btn-primary" @click.prevent="finish()" v-if="isEdit">
                        Finish
                        <i class="fa fa-arrow-right"></i>
                      </button>
                    </div> -->
                    <!-- End: Previous and Next Buttons -->
                  </form>
                </section>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <activate-member :user-data="user_data" :user-type="'CUSTOMER'"
      :toggle-activate-member-popup="toggleActivateMemberPopup" @toggleActivateMemberPopup="toggleActivateMemberPopup"
      v-if="show_activate_user_modal">
    </activate-member>

    <next-of-kin-popup :user-data="user_data" :next-of-kin-data="next_of_kin_data" :isEdit="isEditNextOfKin"
      :isShow="isShowNextOfKin" :user-type="'CUSTOMER'" :toggle-next-of-kin-popup="toggleNextOfKinPopup"
      @toggleNextOfKinPopup="toggleNextOfKinPopup" v-if="show_next_of_kin_modal">
    </next-of-kin-popup>

    <user-bank-popup :user-data="user_data" :user-bank-data="user_bank_data" :isEdit="isEditUserBank"
      :isShow="isShowUserBank" :user-type="'CUSTOMER'" :toggle-user-bank-popup="toggleUserBankPopup"
      @toggleUserBankPopup="toggleUserBankPopup" v-if="show_user_bank_modal">
    </user-bank-popup>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted, toRefs, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, maxLength, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { formatSize } from "../../../../filters";
import { isEmpty } from "../../../../utils";
import "./style/registration.scss";

const customerFormGd = {
  first_name: "Nikk",
  last_name: "Customer",
  user_type: "CUSTOMER",
  password: "123456",
  password_confirmation: "123456",
  id_no: "23432",
  date_of_birth: "1992-08-09",
  gender: "Male",
  is_group: 0,
  email: "nikk@test.com",
  phone: "254720743220",
  phone_country: "KE",
  alternative_phone: "254720743221",
  alternative_phone_country: "KE",
  marital_status_id: "1",
  country_id: "113",
  nationality_id: "113",
  county_id: undefined,
  constituency_id: undefined,
  ward_id: undefined,
  monthly_target_savings: 2000,
  monthly_target_savings_date: "05",
  source_of_income: "Egg Hawking",
  auto_generate_password: true,
  change_password_next_login: true,
  // status_id: "1",
  company_id: 2,
  branch_id: 2,
  customer_id: undefined,
  street_address: "Kenyatta Ave",
  postal_code: "00100",
  postal_address: "5342",
  latitude: "-1.284569235418726",
  longitude: "36.888678073883064",
  religion: "christian",
  member_photo: [],
  member_kra_pin: [],
  member_signature: [],
  member_reg_form: [],
  next_of_kins: [],
};

const customerForm = {
  first_name: "",
  last_name: "",
  user_type: "CUSTOMER",
  password: "",
  password_confirmation: "",
  id_no: "",
  date_of_birth: "",
  gender: "",
  is_group: 0,
  email: "",
  phone: "",
  phone_country: "KE",
  alternative_phone: "",
  alternative_phone_country: "KE",
  marital_status_id: "1",
  country_id: "113",
  nationality_id: "113",
  county_id: undefined,
  constituency_id: undefined,
  ward_id: undefined,
  monthly_target_savings: undefined,
  monthly_target_savings_date: "",
  source_of_income: "",
  auto_generate_password: true,
  change_password_next_login: true,
  // status_id: "1",
  company_id: "",
  branch_id: "",
  customer_id: undefined,
  street_address: "",
  postal_code: "",
  postal_address: "",
  latitude: "-1.284569235418726",
  longitude: "36.888678073883064",
  religion: "",
  member_photo: [],
  member_kra_pin: [],
  member_signature: [],
  member_reg_form: [],
  next_of_kins: [],
  team_id: ""
};

const customer = {
  gender: "1",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  zipCode: "",
  city: "",
  birthDay: "",
  termOfService: "",
  pinCode: "",
  eMail: "",
};

let hasSeenCongrats = false;
const tempCustomer = {
  gender: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  zipCode: "",
  city: "",
  birthDay: "",
  termOfService: "",
  pinCode: "",
  eMail: "",
};

const memberPhotoFile = ref({});

const module = "customers";
const company_module = "companies";
const branch_module = "branches";
const country_module = "countries";
const county_module = "counties";
const constituency_module = "constituencies";
const ward_module = "wards";
const statuses_module = "statuses";
const maritalstatuses_module = "maritalstatuses";
const nextofkin_module = "nextofkins";
const userbank_module = "userbanks";
const team_module = 'teams';
// let steps = {};
// let step = 1;

export default defineComponent({
  name: "CustomerDetail",
  // steps: {},
  // step: 1,
  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    authUser: {
      required: false,
    },
    isMakerCheckerEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerCheckerMaxLevel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm", "submitApprovalForm"],

  setup(props, { emit }) {
    // avoid destructuring props directly in the root scope of setup
    const customerProp = toRefs(props);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    // const resetForm = props.resetForm;

    const show_activate_user_modal = ref(false);
    const show_next_of_kin_modal = ref(false);
    const show_user_bank_modal = ref(false);
    const user_data = ref(null);
    const isEditNextOfKin = ref(false);
    const isShowNextOfKin = ref(false);
    const next_of_kin_data = ref(null);
    const isEditUserBank = ref(false);
    const isShowUserBank = ref(false);
    const user_bank_data = ref(null);
    const steps = ref({});
    const step = ref(1);

    let customer = ref(customerForm);

    // customer id
    const customer_id = route.params && route.params.id;

    // if we are in edit mode, get customer data from db
    if (props.isEdit || props.isShow) {
      customer = computed(() => store.getters[`${module}/customer`]);
    }

    // if we are in maker checker, use maker checker data
    if (customerProp.isMakerChecker.value) {
      customer = customerProp.makerChecker.value?.data;
    }

    // let steps = {};
    // let step = 1;

    const rules = {
      first_name: { required },
      last_name: { required },
      user_type: {},
      password: {},
      password_confirmation: {},
      id_no: { required },
      date_of_birth: { required },
      gender: { required },
      is_group: { required },
      email: { email },
      phone: {},
      phone_country: {},
      alternative_phone: {},
      alternative_phone_country: {},
      marital_status_id: {},
      country_id: { required },
      nationality_id: {},
      county_id: {},
      constituency_id: {},
      ward_id: {},
      monthly_target_savings: {},
      monthly_target_savings_date: {
        maxLength: maxLength(2),
        minLength: minLength(2),
      },
      source_of_income: {},
      auto_generate_password: {},
      change_password_next_login: {},
      // status_id: { },
      company_id: { required },
      branch_id: { required },
      street_address: {},
      postal_code: {},
      postal_address: {},
      latitude: {},
      longitude: {},
      religion: {},
      member_photo: {},
      member_signature: {},
      member_document: {},
      team_id: {}
    };

    // validate form
    const model = useVuelidate(rules, customer);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);

    // load countries
    store.dispatch(`${country_module}/fetchCountries`, payload);

    // load counties
    let county_payload = { country_id: "113", report: "1" };
    store.dispatch(`${county_module}/fetchCounties`, county_payload);

    // load marital statuses
    store.dispatch(`${maritalstatuses_module}/fetchMaritalStatuses`, payload);

    // load statuses data
    let status_payload = {
      report: "1", // fetch all data
      section: "users", // get users statuses only
    };
    store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);

    // fetch user banks from store
    async function fetchUserBanks() {
      let user_banks_payload = { customer_id: customer_id, report: "1" };
      await store.dispatch(`${userbank_module}/fetchUserBanks`, user_banks_payload);
    }

    // fetch next of kins from store
    async function fetchNextOfKins() {
      let next_of_kins_payload = { customer_id: customer_id, report: "1" };
      await store.dispatch(`${nextofkin_module}/fetchNextOfKins`, next_of_kins_payload);
    }

    function previous() {
      this.step--;
    }
    function next() {
      this.step++;
    }

    function finish() {
      router.push({ name: "customerHome" });
    }

    function customerRegister() {
      hasSeenCongrats = true;
    }

    /* function onDateOfBirthChange(date) {
      customer.value.date_of_birth = date;
    } */

    // fetch customer record from store
    /* async function fetchCustomer() {
                    await store.dispatch(`${module}/fetchCustomer`, customer_id);
                } */

    // Are we in edit mode?
    if (props.isEdit) {
      // fetch next of kins
      fetchNextOfKins();

      // fetch user banks
      fetchUserBanks();

      payload = { report: "1", company_id: customer.value.company_id };
      store.dispatch(`${branch_module}/fetchBranches`, payload);

      // START ON PAGE REFRESH
      // check if we should fetch counties
      if (customer.value.country_id) {
        let country_payload = {
          country_id: customer.value.country_id,
          report: "1",
        };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }

      // check if we should fetch constituencies
      if (customer.value.county_id) {
        let county_payload = {
          county_id: customer.value.county_id,
          report: "1",
        };
        store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
      }

      // check if we should fetch wards
      if (customer.value.constituency_id) {
        let constituency_payload = {
          constituency_id: customer.value.constituency_id,
          report: "1",
        };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
      // END ON PAGE REFRESH
    }

    if (!props.isEdit && !props.isShow) {
      // set default company
      onMounted(() => {
        // set default company id
        customer.value.company_id = props.authUser.active_company_id;
      });
    }

    // START ON ROUTE CHANGES
    // watch for country changes
    watch(
      () => customer.value.country_id,
      (newVal, oldVal) => {
        // fetch counties
        let country_payload = { country_id: newVal, report: "1" };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }
    );

    // watch for county changes
    watch(
      () => customer.value.county_id,
      (newVal, oldVal) => {
        // fetch constituencies
        let county_payload = { county_id: newVal, report: "1" };
        store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
      }
    );

    // watch for constituency changes
    watch(
      () => customer.value.constituency_id,
      (newVal, oldVal) => {
        // fetch wards
        let constituency_payload = { constituency_id: newVal, report: "1" };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
    );
    // END ON ROUTE CHANGES

    // toggleActivateMemberPopup
    function toggleActivateMemberPopup(customer) {
      show_activate_user_modal.value = !show_activate_user_modal.value;
      user_data.value = customer;
      // console.log("customer", customer);
    }

    // toggleNextOfKinPopup
    function toggleNextOfKinPopup(customer) {
      // console.log("custdetail", customer);
      show_next_of_kin_modal.value = !show_next_of_kin_modal.value;
      user_data.value = customer;
      fetchNextOfKins();
    }

    // createNextOfKinPopup
    function createNextOfKinPopup() {
      // console.log("sent nextofkin ******************** ", nextofkin);
      show_next_of_kin_modal.value = !show_next_of_kin_modal.value;
      user_data.value = customer.value;
      // next_of_kin_data.value = nextofkin;
      isEditNextOfKin.value = false;
      isShowNextOfKin.value = false;
    }

    // editNextOfKinPopup
    function editNextOfKinPopup(nextofkin) {
      // console.log("sent nextofkin ******************** ", nextofkin);
      show_next_of_kin_modal.value = !show_next_of_kin_modal.value;
      user_data.value = customer.value;
      next_of_kin_data.value = nextofkin;
      isEditNextOfKin.value = true;
      isShowNextOfKin.value = false;
    }

    // showNextOfKinPopup
    function showNextOfKinPopup(nextofkin) {
      // console.log("sent nextofkin ******************** ", nextofkin);
      show_next_of_kin_modal.value = !show_next_of_kin_modal.value;
      user_data.value = customer.value;
      next_of_kin_data.value = nextofkin;
      isEditNextOfKin.value = true;
      isShowNextOfKin.value = true;
    }

    // toggleNextOfKinPopup
    function toggleNextOfKinPopupSubmitted(nextofkin) {
      show_next_of_kin_modal.value = !show_next_of_kin_modal.value;
      // show_next_of_kin_modal.value = false;
      // add new next of kin
      // console.log("custdetail nextofkin", nextofkin);
      // if (nextofkin) {
      customer.value.next_of_kins.push(nextofkin);
      // }
    }

    /* start user banks */

    // toggleUserBankPopup
    function toggleUserBankPopup(customer) {
      show_user_bank_modal.value = !show_user_bank_modal.value;
      user_data.value = customer;
      // console.log("customer", customer);
      fetchUserBanks();
    }

    // createUserBankPopup
    function createUserBankPopup() {
      show_user_bank_modal.value = !show_user_bank_modal.value;
      user_data.value = customer.value;
      isEditUserBank.value = false;
      isShowUserBank.value = false;
    }

    // editUserBankPopup
    function editUserBankPopup(userbank) {
      // console.log("sent userbank ******************** ", userbank);
      show_user_bank_modal.value = !show_user_bank_modal.value;
      user_data.value = customer.value;
      user_bank_data.value = userbank;
      isEditUserBank.value = true;
      isShowUserBank.value = false;
    }

    // showUserBankPopup
    function showUserBankPopup(userbank) {
      // console.log("sent userbank ******************** ", userbank);
      show_user_bank_modal.value = !show_user_bank_modal.value;
      user_data.value = customer.value;
      user_bank_data.value = userbank;
      isEditUserBank.value = true;
      isShowUserBank.value = true;
    }

    /* end user banks */

    if (props.resetForm) {
      // reset form
      // customerForm.reset();
      var thisForm = document.getElementsByName("member-details");
      thisForm.reset();
    }

    function onSubmitted() {
      emit("submitForm", customer);
    }

    function onCancelled() {
      emit("cancelForm", customer);
    }

    function onEdit() {
      emit("editForm", customer);
    }

    function onSubmitApproval() {
      emit("submitApprovalForm", customer);
    }

    function changedLocation(location) {
      // console.log("changedLocation ", location);
      customer.value.latitude = location.lat;
      customer.value.longitude = location.lng;
      // console.log("customer after changed ", customer.value);
    }

    function checkAutoGeneratePassword(event) {
      if (event.target.checked) {
        customer.value.auto_generate_password = true;
      } else {
        customer.value.auto_generate_password = false;
      }
    }

    function onChangeCountry(event) {
      // console.log("onChangeCountry event", event.target.value);
      // store.dispatch(`${constituency_module}/clearValues`);
      // store.dispatch(`${ward_module}/clearValues`);
      let country_payload = { country_id: event.target.value, report: "1" };
      store.dispatch(`${county_module}/fetchCounties`, country_payload);
    }

    function onChangeCounty(event) {
      // console.log("onChangeCounty event", event.target);
      let county_payload = { county_id: event.target.value, report: "1" };
      store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
    }

    function onChangeConstituency(event) {
      // console.log("onChangeConstituency event", event.target);
      let constituency_payload = {
        constituency_id: event.target.value,
        report: "1",
      };
      store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
    }

    function fetchBranches(event) {
      // console.log("event", event);
      store.dispatch(`${branch_module}/fetchBranches`, event.target.value);
    }

    function checkMustChangePasswordNextLogin(event) {
      if (event.target.checked) {
        customer.value.change_password_next_login = true;
      } else {
        customer.value.change_password_next_login = false;
      }
    }

    function onMemberPhotoUploadSuccess(res) {
      // show image - replace in customer object
      customer.value.member_photo[0] = res.data.data;
    }

    function onMemberSignatureUploadSuccess(res) {
      customer.value.member_signature[0] = res.data.data;
    }

    function onMemberIdFrontUploadSuccess(res) {
      customer.value.member_id_front[0] = res.data.data;
    }

    function onMemberIdBackUploadSuccess(res) {
      customer.value.member_id_back[0] = res.data.data;
    }

    function onMemberKraPinUploadSuccess(res) {
      customer.value.member_kra_pin[0] = res.data.data;
    }

    function onMemberRegFormUploadSuccess(res) {
      customer.value.member_reg_form[0] = res.data.data;
    }

    // TODO :: Fred: dont limit teams to page 1, instead fetch all, using report=1
    async function fetchTeams() {
      let payload = { 'page': '1' };
      await store.dispatch(`${team_module}/fetchTeams`, payload)
    }
    // update location
    const updateLatLng = ({ lat, lng }) => {
      customer.value.company_latitude = lat;
      customer.value.company_longitude = lng;
    };
    onMounted(async () => {
      await fetchTeams();


    });
    const team_parameters = reactive({
      branch_id: "",
      team_id: "",
      team_details: "",
      page: 1,
      show_adv_search: true,
      search_terms: "",
      status_id: 0
    });
    store.dispatch(`${team_module}/fetchTeams`, team_parameters);


    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      branches: computed(() => store.getters[`${branch_module}/branches`]),
      countries: computed(() => store.getters[`${country_module}/countries`]),
      counties: computed(() => store.getters[`${county_module}/counties`]),
      constituencies: computed(
        () => store.getters[`${constituency_module}/constituencies`]
      ),
      wards: computed(() => store.getters[`${ward_module}/wards`]),
      statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      maritalstatuses: computed(
        () => store.getters[`${maritalstatuses_module}/maritalstatuses`]
      ),
      userbanks: computed(() => store.getters[`${userbank_module}/userbanks`]),
      nextofkins: computed(() => store.getters[`${nextofkin_module}/nextofkins`]),
      customer,
      model,
      step,
      steps,
      previous,
      finish,
      next,
      customerRegister,
      // isEdit: props.isEdit,
      // isShow: props.isShow,
      onSubmitted,
      onCancelled,
      onEdit,
      formatSize,
      memberPhotoFile,
      isEmpty,
      onMemberPhotoUploadSuccess,
      onMemberSignatureUploadSuccess,
      onMemberIdFrontUploadSuccess,
      onMemberIdBackUploadSuccess,
      onMemberKraPinUploadSuccess,
      onMemberRegFormUploadSuccess,
      checkAutoGeneratePassword,
      checkMustChangePasswordNextLogin,
      fetchBranches,
      changedLocation,
      onChangeCountry,
      onChangeCounty,
      onChangeConstituency,
      toggleActivateMemberPopup,
      toggleNextOfKinPopup,
      toggleNextOfKinPopupSubmitted,
      toggleUserBankPopup,
      show_activate_user_modal,
      show_next_of_kin_modal,
      show_user_bank_modal,
      user_data,
      next_of_kin_data,
      user_bank_data,
      editNextOfKinPopup,
      createNextOfKinPopup,
      showNextOfKinPopup,
      isEditNextOfKin,
      isShowNextOfKin,
      editUserBankPopup,
      createUserBankPopup,
      showUserBankPopup,
      isEditUserBank,
      isShowUserBank,
      updateLatLng,
      teams: computed(() => store.getters[`${team_module}/teams`]),
      onSubmitApproval,
      // onDateOfBirthChange,
    };
  },
});
</script>
