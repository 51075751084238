const failedJobRoutes = [
    {
        name: 'failedJobsHome',
        path: '',
        component: () => import('./Index.vue'),
        meta: {
            auth: true,
            title: 'Failed Jobs Listing',
            breadcrumb: 'Failed Jobs Listing',
            permission: []
        }
    },

];
export default failedJobRoutes;
