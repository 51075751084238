const depositAccountRoutes = [

    {

        path: '',
        name: 'depositHome',
        component: () => import('./ListDepositAccounts.vue'),
        meta: {
            auth: true,
            title: 'Current Accounts',
            breadcrumb: 'Current Accounts',
            permission: ['view currentdeposits']
        }

    },

    {

        path: 'balance',
        name: 'depositBalance',
        component: () => import('./ListDepositBalance.vue'),
        meta: {
            auth: true,
            title: 'Deposits Balances',
            breadcrumb: 'Deposits Balances',
            permission: ['view currentdeposits']
        }

    },

    {
        path: 'customer-deposits/:customerId',
        name: 'customerDeposits',
        component: () =>import ('./ListDepositAccounts.vue'),
        meta: {
            auth: true,
            title: 'Current Accounts',
            breadcrumb: 'Current Accounts',
            permission: ['view currentdeposits']
        },

    },

    {
        path: 'deposit-statement/:deposit_application_id',
        name: 'depositStatement',
        component: () =>import ('./DepositStatement.vue'),
        meta: {
            auth: true,
            title: 'Deposit Statement',
            breadcrumb: 'Deposit Statement',
            permission: ['view currentdeposits']
        },

    },

    {
        path: 'calllog/:id',
        name: 'depositcustomercall',
        component: () => import('./../../Registration/CustomerCall/CustomerCalls.vue'),
        meta: {
            auth: true,
            title: 'Member Call Logs',
            breadcrumb: 'Member Call Logs',
            permission: ['view member']
        }
    },

    {
        path: 'create',
        name: 'createDeposit',
        component: () => import('./CreateEditDeposit.vue'),
        meta: {
            auth: true,
            title: 'Create Deposit Account',
            breadcrumb: 'Create Deposit Account',
            permission: ['create currentdeposits']
        }
    },



    {
        path: ':id/edit',
        name: 'editDeposit',
        component: () => import('./CreateEditDeposit.vue'),
        meta: {
            auth: true,
            title: 'Edit Deposit Account',
            breadcrumb: 'Edit Deposit Account',
            permission: ['update currentdeposits']
        }
    },

    {
        name: 'viewAccountDetails',
        path: 'account-details/:depositApplicationId',
        component: () => import('./AccountDetails.vue'),
        meta: {
            auth: true,
            title: 'Account Details',
            breadcrumb: 'Account Details',
            permission: ['view currentdeposits']
        }
    },
    

    {
        path: 'delete/:id',
        name: 'deleteDeposit',
        meta: {
            auth: true,
            title: 'Delete Deposit Account',
            permission: ['delete currentdeposits']
        }
    },

];

export default depositAccountRoutes;
