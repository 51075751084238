import depositAccountRoutes from "./DepositAccount/depositAccountRoutes";
import loanRoutes from "./Loan/loanRoutes";
import loanapplicationRoutes from "./LoanApplication/loanapplicationRoutes";
import loanExposureLimitRoutes from "./LoanExposureLimit/loanExposureLimitRoutes";
import shareAccountRoutes from "./ShareDepositAccount/shareAccountRoutes";
import Base from '../../Common/Base.vue'
import collateralRoutes from "./collateral/collateralRoutes";
import businessAppraisalRoutes from "./BusinessAppraisal/businessAppraisalRoutes";
import businessCategoryRoutes from "./BusinessCategory/businessCategoryRoutes";
import fixedAccountRoutes from "./FixedDepositAccount/fixedAccountRoutes";
import pendingDepositLoanRoutes from "./PendingDepositLoan/pendingDepositLoanRoutes";

const depositLoanModuleRoutes = [

    {

        path: '',
        name: 'depositLoanHome',
        component: () => import('./HomeDepositLoan.vue'),
        meta: {
            auth: true,
            title: 'Deposits & Loans',
            breadcrumb: 'Deposits & Loans',
            permission: []
        },

    },

    {

        name: 'depositHomes',
        path: 'deposit',
        component: Base,
        meta: {
            auth: true,
            title: 'Current',
            breadcrumb: 'Current',
            //permission: ['view depositaccount', 'create depositaccount', 'update depositaccount', 'delete depositaccount','view currentdeposits']
            permission: ['view currentdeposits','create currentdeposits','update currentdeposits','delete currentdeposits']
        },

        children: depositAccountRoutes

    },

    {

        name: 'shareHomes',
        path: 'share-accounts',
        component: Base,
        meta: {
            auth: true,
            title: 'Shares Deposit Accounts',
            breadcrumb: 'Shares Deposit Accounts',
            permission: ['view shares accounts', 'create shares accounts', 'create shares purchase',
            'create shares redemption','create shares transfer','update shares accounts','delete shares accounts']
        },

        children: shareAccountRoutes

    },

    {

        name: 'loanApplicationHomes',
        path: 'loan-applications',
        component: Base,
        meta: {
            auth: true,
            title: 'Loan Applications Listing',
            breadcrumb: 'Loan Applications Listing',
            permission: ['view loanapplication', 'create loanapplication', 'update loanapplication', 'delete loanapplication']
        },

        children: loanapplicationRoutes

    },

    {

        name: 'loanHomes',
        path: 'loans',
        component: Base,
        meta: {
            auth: true,
            title: 'Loans',
            breadcrumb: 'Loans',
            permission: ['view loan', 'create loan', 'update loan', 'delete loan']
        },

        children: loanRoutes

    },

    {

        path: 'loan-exposure-limits',
        name: 'loanExposureLimitHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Loan Exposure Limits',
            breadcrumb: 'Loan Exposure Limits',
            permission: ['view loanexposurelimit', 'create loanexposurelimit', 'update loanexposurelimit', 'delete loanexposurelimit']
        },

        children: loanExposureLimitRoutes

    },
    {

        path: 'collaterals',
        name: 'collateralHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Collaterals',
            breadcrumb: 'Collaterals',
            permission: [] //'view collateral', 'create collateral', 'update collateral', 'delete collateral'
        },

        children: collateralRoutes

    },
    
    {
        path: 'business-appraisals',
        name: 'businessAppraisals',
        component: Base,
        meta: {
            auth: true,
            title: 'Business Appraisals',
            breadcrumb: 'Business Appraisals',
            permission: [] 
        },

        children: businessAppraisalRoutes

    },

    {

        path: 'business-categories',
        name: 'businesscategories',
        component: Base,
        meta: {
            auth: true,
            title: 'Business Categories',
            breadcrumb: 'Business Categories',
            permission: [] 
        },

        children: businessCategoryRoutes
    },

    {

        path: 'pending-items',
        name: 'pendingDepositLoanHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Pending Items',
            breadcrumb: 'Pending Items',
            permission: ['view makerchecker', 'update makerchecker']
        },

        children: pendingDepositLoanRoutes

    },
    
    {

        path: 'pending-items',
        name: 'pendingDepositLoanHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Pending Items',
            breadcrumb: 'Pending Items',
            permission: ['view makerchecker', 'update makerchecker']
        },

        children: pendingDepositLoanRoutes

    },

    {
        name: 'fixedAccountsHomes',
        path: 'fixed-accounts',
        component: Base,
        meta: {
            auth: true,
            title: 'Fixed',
            breadcrumb: 'Fixed',
            permission: ['view fixed accounts','create fixed accounts','update fixed accounts','delete fixed accounts']
        },

        children: fixedAccountRoutes
    },

    // {
    //     name: 'currentDepositAccountsHome',
    //     path: 'current-deposit-accounts',
    //     component: Base,
    //     meta: {
    //         auth: true,
    //         title: 'Current Deposit Account',
    //         breadcrumb: 'Current Deposit Account',
    //         permission: []
    //     },

    //     children: depositAccountRoutes

    // },

    {
        name: 'fixedAccountsHomes',
        path: 'fixed-accounts',
        component: Base,
        meta: {
            auth: true,
            title: 'Fixed',
            breadcrumb: 'Fixed',
            permission: ['view fixed accounts','create fixed accounts','update fixed accounts','delete fixed accounts']
        },

        children: fixedAccountRoutes
    },

    // {
    //     name: 'currentDepositAccountsHome',
    //     path: 'current-deposit-accounts',
    //     component: Base,
    //     meta: {
    //         auth: true,
    //         title: 'Current Deposit Account',
    //         breadcrumb: 'Current Deposit Account',
    //         permission: []
    //     },

    //     children: depositAccountRoutes

    // },

];

export default depositLoanModuleRoutes;
