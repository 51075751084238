const reportFinanceRoutes = [

    {
        path: 'ledger-transactions',
        name: 'ledgerTransactions',
        component: () => import('./LedgerTransaction.vue'),
        meta: {
            auth: true,
            title: 'Ledger Transactions',
            breadcrumb: 'Ledger Transactions',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },
    {
        path: 'ledgers/:account_id/:branch_id/:from_date/:to_date',
        name: 'ledgerdetail',
        component: () => import('./LedgerTransaction.vue'),
        meta: {
            auth: true,
            title: 'Ledger Transactions',
            breadcrumb: 'Ledger Transactions',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

    {
        path: 'trial-balance',
        name: 'trialBalance',
        component: () => import('./TrialBalance.vue'),
        meta: {
            auth: true,
            title: 'Trial Balance',
            breadcrumb: 'Trial Balance',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

    {
        path: 'income-statement',
        name: 'incomeStatement',
        component: () => import('./IncomeStatement.vue'),
        meta: {
            auth: true,
            title: 'Income Statement',
            breadcrumb: 'Income Statement',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

    {
        path: 'balance-sheet',
        name: 'balanceSheet',
        component: () => import('./BalanceSheet.vue'),
        meta: {
            auth: true,
            title: 'Balance Sheet',
            breadcrumb: 'Balance Sheet',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

    {
        path: 'journal-tranasctions',
        name: 'journalTransactions',
        component: () => import('./JournalTransactions.vue'),
        meta: {
            auth: true,
            title: 'Journal Transactions',
            breadcrumb: 'Journal Transactions',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

    {
        path: 'single-journal/:trans_no',
        name: 'singlejournal',
        component: () => import('./JournalTransactions.vue'),
        meta: {
            auth: true,
            title: 'Journal Transactions',
            breadcrumb: 'Journal Transactions',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        }
    },

];

export default reportFinanceRoutes;
