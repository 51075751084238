const productSettingRoutes = [

    {

        name: 'productSettingHome',
        path: '',
        component: () => import('./ListProductSettings.vue'),
        meta: {
            auth: true,
            title: 'Loan Product Settings Listing',
            breadcrumb: 'Loan Product Settings Listing',
            permission: ['view loansetting']
        }

    },

    {
        path: 'create',
        name: 'createProductSetting',
        component: () => import('./CreateProductSetting.vue'),
        meta: {
            auth: true,
            title: 'Create Loan Product Setting',
            breadcrumb: 'Create Loan Product Setting',
            permission: ['create loansetting']
        }
    },

    {
        path: 'category',
        name: 'productcategories',
        component: () => import('./ListProductCategory.vue'),
        meta: {
            auth: true,
            title: 'Product Category Listing',
            breadcrumb: 'Product Category Listing',
            permission: []
        }
    },

    {
        path: ':id',
        name: 'showProductSetting',
        component: () => import('./ShowProductSetting.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Product Setting',
            breadcrumb: 'Showing Loan Product Setting',
            permission: ['view loansetting']
        }
    },

    {
        path: ':id/edit',
        name: 'editProductSetting',
        component: () => import('./EditProductSetting.vue'),
        meta: {
            auth: true,
            title: 'Edit Loan Product Setting',
            breadcrumb: 'Edit Loan Product Setting',
            permission: ['update loansetting']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteProductSetting',
        meta: {
            auth: true,
            title: 'Delete Loan Product Setting',
            permission: ['delete loansetting']
        }
    },

];

export default productSettingRoutes;
