const SharesManagementRoutes = [
    {
        path: 'shares-transfer',
        name: 'sharestransfer',
        component: () => import('./SharesTransfer.vue'),
        meta: {
            auth: true,
            title: 'Shares Transfer',
            breadcrumb: 'Shares Transfer',
            permission: []
        },
    },

    {
        path: 'shares-purchase',
        name: 'sharespurchase',
        component: () => import('./SharesPurchase.vue'),
        meta: {
            auth: true,
            title: 'Shares Purchase',
            breadcrumb: 'Shares Purchase',
            permission: []
        },
    },

    {
        path: 'shares-redemption',
        name: 'sharesredemption',
        component: () => import('./SharesRedemption.vue'),
        meta: {
            auth: true,
            title: 'Shares Redemption',
            breadcrumb: 'Shares Redemption',
            permission: []
        },
    },

];

export default SharesManagementRoutes;