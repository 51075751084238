<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <!-- <pre>isMakerChecker ::: {{ isMakerChecker }}</pre>
            <pre>isShow ::: {{ isShow }}</pre>
            <pre>paybill ::: {{ paybill }}</pre> -->
            <!-- <pre>formData ::: {{ formData }}</pre> -->

            <div class="row">
              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.paybill_no.$invalid }"
                >
                  <label for="paybill_no" class="col-sm-4 col-form-label text-right">
                    Paybill No
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :disabled="isShow"
                      :class="{ 'form-control-danger': model.paybill_no.$invalid }"
                      type="text"
                      v-model="model.paybill_no.$model"
                    />

                    <error-msg :property="model.paybill_no" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.paybill_name.$invalid }"
                >
                  <label for="paybill_name" class="col-sm-4 col-form-label text-right">
                    Paybill Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :disabled="isShow"
                      :class="{ 'form-control-danger': model.paybill_name.$invalid }"
                      type="text"
                      v-model="model.paybill_name.$model"
                    />

                    <error-msg :property="model.paybill_name" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.paybill_type.$invalid }"
                >
                  <label for="paybill_type" class="col-sm-4 col-form-label text-right"
                    >Paybill Type</label
                  >
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      v-model="model.paybill_type.$model"
                      :disabled="isShow"
                    >
                      <option value="" selected v-if="!isEdit">Select Type</option>
                      <option value="paybill">Paybill</option>
                      <option value="shortcode">Shortcode</option>
                      <option value="till">Till</option>
                    </select>

                    <error-msg :property="model.paybill_type" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="company_id" class="col-sm-4 col-form-label text-right">
                    Company Name
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.company_id.$model"
                      v-if="companies.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Company</option>
                      <option
                        v-for="(item, index) in companies.data"
                        :key="index"
                        :value="item.company_id"
                      >
                        {{ item.company_name }}
                      </option>
                    </select>
                    <error-msg :property="model.company_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="payment_type_id" class="col-sm-4 col-form-label text-right">
                    Payment Type
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.payment_type_id.$model"
                      v-on:change="onPaymentTypeChange"
                      v-if="paymentTypes"
                    >
                      <option value="" selected v-if="!isEdit">
                        Select Payment Type
                      </option>
                      <option
                        v-for="(item, index) in paymentTypes"
                        :key="index"
                        :value="item?.payment_type_id"
                      >
                        {{ item?.payment_name }}
                      </option>
                    </select>
                    <error-msg :property="model.payment_type_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.paybill_description.$invalid }"
                >
                  <label
                    for="paybill_description"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Paybill Description
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :disabled="isShow"
                      :class="{
                        'form-control-danger': model.paybill_description.$invalid,
                      }"
                      type="text"
                      v-model="model.paybill_description.$model"
                    />

                    <error-msg :property="model.paybill_description" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="bank_account_id" class="col-sm-4 col-form-label text-right">
                    Bank Account
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      v-model="model.bank_account_id.$model"
                      :disabled="isShow"
                    >
                      <option value="" selected v-if="!isEdit">
                        Select Bank Account
                      </option>
                      <option
                        v-for="(item, index) in bankaccounts"
                        :key="index"
                        :value="item.account_id"
                      >
                        {{ item.account_code_name }}
                      </option>
                    </select>
                    <error-msg :property="model.bank_account_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.status_id.$invalid }"
                >
                  <label for="status_id" class="col-sm-4 col-form-label text-right"
                    >Status</label
                  >
                  <div class="col-sm-8">
                    <div>
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_active"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="1"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_active"
                            >Active</label
                          >
                        </div>
                      </div>

                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_inactive"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="99"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_inactive"
                            >Inactive</label
                          >
                        </div>
                      </div>
                    </div>

                    <error-msg :property="model.status_id" />
                  </div>
                </div>
              </div>

              <!-- show maker checker form buttons/ status -->
              <maker-checker-button-component
                :makerChecker="makerChecker"
                v-if="isMakerChecker"
              />

              <!-- show regular form buttons -->
              <div class="col-lg-12" v-else>
                <hr />
                <div class="form-group">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                    v-if="!isShow"
                  >
                    <slot name="submitButton"></slot>
                  </button>

                  <button
                    class="btn btn-primary mr-3"
                    type="button"
                    @click="onEdit"
                    v-if="isShow"
                  >
                    Edit
                  </button>

                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const paybillForm = {
  paybill_name: "Test Paybill",
  paybill_no: "546373",
  paybill_type: "paybill",
  paybill_description: "Test Paybill",
  company_id: "2",
  payment_type_id: "",
  bank_account_id: "",
  status_id: "1",
  paybill_id: undefined,
};

const paybillForm_gd = {
  paybill_name: "",
  paybill_no: "",
  paybill_type: "paybill",
  paybill_description: "",
  company_id: "",
  payment_type_id: "",
  bank_account_id: "",
  status_id: "1",
  paybill_id: undefined,
};

const module = "paybills";
const company_module = "companies";
const payment_method_module = "paymentmethods";
const financeaccounts_module = "financeaccounts";

export default defineComponent({
  name: "PaybillDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // console.log("props.makerCheckerAction === ", props.makerCheckerAction);

    // avoid destructuring props directly in the root scope of setup
    const paybillProp = toRefs(props);

    let paybill = ref(paybillForm);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);
    store.dispatch(`${payment_method_module}/fetchPaymentMethods`, payload);
    store.dispatch(`${financeaccounts_module}/fetchPostingAccounts`);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      paybill = computed(() => store.getters[`${module}/paybill`]);
    }

    // if we are in maker checker, use maker checker data
    if (paybillProp.isMakerChecker.value) {
      paybill = paybillProp.makerChecker.value?.data;
    }

    // console.log("paybill", paybill.value);

    const rules = {
      paybill_name: {},
      paybill_no: { required },
      paybill_type: { required },
      paybill_description: {},
      company_id: { required },
      payment_type_id: { required },
      bank_account_id: { required },
      status_id: {},
      paybill_id: {},
    };

    // validate form
    const model = useVuelidate(rules, paybill);

    // return formData
    /* const formData = computed(() => {
      return paybill;
    }); */

    function onPaymentTypeChange() {
      //getting the payment method
      var payment = paymentTypes.value.find(
        (e) => e.payment_type_id == paybill?.value?.payment_type_id
      );
      paybill.value.bank_account_id = payment ? payment.bank_account_id : "";
    }

    function onSubmitted() {
      emit("submitForm", paybill);
    }

    function onCancelled() {
      emit("cancelForm");
    }

    function onSubmitForm() {
      emit("submitForm");
    }

    function onEdit() {
      emit("editForm", branch);
    }

    const paymentTypes = computed(
      () => store.getters[`${payment_method_module}/paymentmethods`]
    );

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      paymentTypes,
      bankaccounts: computed(
        () => store.getters[`${financeaccounts_module}/bankingAccounts`]
      ),
      model,
      paybill: computed(() => store.getters[`${module}/paybill`]),
      // paybill,
      onSubmitted,
      onPaymentTypeChange,
      onCancelled,
      onSubmitForm,
      onEdit,
      // formData,
    };
  },
});
</script>
