const loanTemplatesRoutes = [
    {
        name: 'loanTemplatesHome',
        path: '',
        component: () => import('./ListLoanTemplates.vue'),
        meta: {
            auth: true,
            title: 'Loan Offer Letter Templates Listing',
            breadcrumb: 'Loan Offer Letter Templates Listing',
            permission: []
        }
    },
    {
        name: 'loanTemplatesShow',
        path: ':id',
        component: () => import('./ShowLoanTemplate.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Offer Letter Template',
            breadcrumb: 'Showing Loan Offer Letter Template',
            permission: []
        }
    },
    {
        name: 'loanTemplatesEdit',
        path: 'edit/:id',
        component: () => import('./EditLoanOfferLetterTemplate.vue'),
        meta: {
            auth: true,
            title: 'Editing Loan Offer Letter Template',
            breadcrumb: 'Editing Loan Offer Letter Template',
            permission: []
        }
    },


    {
        path: 'show-loan-offer-letter/:id',
        name: 'showLoanOfferLetter',
        component: () =>
            import ('./ShowLoanTemplate.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Offer Letter',
            breadcrumb: 'Loan Offer Letter',
            permission: [],
        }
    },
    {
        name: 'loanTemplateCreate',
        path: 'create',
        component: () => import('./CreateLoanOfferLetterTemplate.vue'),
        meta: {
            auth: true,
            title: 'Create Loan Offer Letter Template',
            breadcrumb: 'Create Loan Offer Letter Template',
            permission: []
        }

    }
];
export default loanTemplatesRoutes;
