const payableFinanceRoutes = [

    {
        path: 'purchase-invoice',
        name: 'createPInvoice',
        component: () => import('./PurchaseInvoice.vue'),
        meta: {
            auth: true,
            title: 'Create Purchase Invoice',
            breadcrumb: 'Create Purchase Invoice',
            //'view purchaseinvoice', 'create purchaseinvoice', 'update purchaseinvoice', 'delete purchaseinvoice'
            //
            permission: ['view purchaseinvoice', 'create purchaseinvoice', 'update purchaseinvoice', 'delete purchaseinvoice']
        }
    },

    {
        path: 'other-payments',
        name: 'otherPayments',
        component: () => import('./OtherPayments.vue'),
        meta: {
            auth: true,
            title: 'Other Payments',
            breadcrumb: 'Other Payments',
            permission: ['view otherpayment', 'create otherpayment', 'update otherpayment', 'delete otherpayment']
        }
    },

    {
        path: 'suppliers',
        name: 'suppliers',
        component: () => import('./SupplierList.vue'),
        meta: {
            auth: true,
            title: 'Suppliers',
            breadcrumb: 'Suppliers',
            permission: ['view supplier', 'create supplier', 'update supplier', 'delete supplier']
        }
    },

    {
        path: 'statements/supplier/:id',
        name: 'supplierStatements',
        component: () => import('./SupplierStatement.vue'),
        meta: {
            auth: true,
            title: 'Supplier Statements',
            breadcrumb: 'Supplier Statements'
        }
    },

    {
        path: 'supplier-payments',
        name: 'vendorPayments',
        component: () => import('./SupplierPayments.vue'),
        meta: {
            auth: true,
            title: 'Supplier Payments',
            breadcrumb: 'Supplier Payments',
            permission: ['view payment', 'create payment', 'update payment', 'delete payment']
        }
    },

    {
        path: 'supplier-adjustments',
        name: 'supplierAdjustments',
        component: () => import('./SupplierAdjustment.vue'),
        meta: {
            auth: true,
            title: ' Supplier Adjustments',
            breadcrumb: ' Supplier Adjustments',
            permission: ['view supplieradjustment', 'create supplieradjustment', 'update supplieradjustment', 'delete supplieradjustment']
        }
    },

];

export default payableFinanceRoutes;
