const businessCategoryRoutes = [

    {
        path: 'business-categories',
        name: 'businesscategory',
        component: () => import ('./ListBusinessCategory.vue'),
        meta: {
            auth: true,
            title: 'Business Categories',
            breadcrumb: 'Business Categories',
            permission: [] 
        }
    },


    {
        path: 'create-businesscategory',
        name: 'createbusinesscategory',
        component: () => import ('./CreateEditBusinessCategory.vue'),
        meta: {
            auth: true,
            title: 'Create Business Category',
            breadcrumb: 'Create Business Category',
            permission: [] 
        }
    },


    {
        path: ':id/edit',
        name: 'editbusinesscategory',
        component: () => import ('./CreateEditBusinessCategory.vue'),
        meta: {
            auth: true,
            title: 'Edit Business Category',
            breadcrumb: 'Edit Business Category',
            permission: []
        }
        
    },

];


export default businessCategoryRoutes;
